import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import MyNavbar from './components/MyNavbar';
import MyFooter from './components/MyFooter';
import HomePage from './pages/HomePage';
import PartsNetworkPage from './pages/services/PartsNetworkPage';
import PartSellerPage from './pages/services/PartSellerPage';
import StorefrontPage from './pages/services/StorefrontPage';
import TestimonialsPage from './pages/services/TestimonialsPage';
import AttachmentsForSalePage from './pages/forsale/AttachmentsPage';
import GuestAttachmentsForSalePage from './pages/forsale/GuestAttachmentsPage';
import EnginesForSalePage from './pages/forsale/EnginesPage';
import MachinesForSalePage from './pages/forsale/MachinesPage';
import TransmissionsForSalePage from './pages/forsale/TransmissionsPage';
import OtherItemsForSalePage from './pages/forsale/OtherItemsPage';
import EnginesGuestForSalePage from './pages/forsale/EngineGuestPage';
import MachinesGuestForSalePage from './pages/forsale/MachinesGuestPage';
import TransmissionsGuestForSalePage from './pages/forsale/TransmissionsGuestPage';
import OtherItemsGuestForSalePage from './pages/forsale/OtherItemsGuestPage';
import GuestAttachmentViewPage from './pages/forsale/GuestAttachmentViewPage';
import AltorferGalleryPage from './pages/galleries/AltorferPage';
import BillMillerGalleryPage from './pages/galleries/BillMillerPage';
import BoydAndSonsGalleryPage from './pages/galleries/BoydAndSonsPage';
import CarterMachineryGalleryPage from './pages/galleries/CarterMachineryPage';
import HeavyQuipGalleryPage from './pages/galleries/HeavyQuipPage';
import IronKingGalleryPage from './pages/galleries/IronKingPage';
import MiltonGalleryPage from './pages/galleries/MiltonPage';
import ThompsonMachineryGalleryPage from './pages/galleries/ThompsonMachineryPage';
import ThompsonTractorGalleryPage from './pages/galleries/ThompsonTractorPage';
import WellerTractorGalleryPage from './pages/galleries/WellerTractorPage';
import YellowstoneValleyGalleryPage from './pages/galleries/YellowstoneValleyPage';
import ContactPage from './pages/ContactPage';
import PrivacyPolicyPage from './pages/legal/PrivacyPolicyPage';
import TermsOfUsePage from './pages/legal/TermsOfUsePage';
import UserRegistrationPage from './pages/UserRegistrationPage';
import PartSearchPage from './pages/partmatcher/PartSearchPage';
import BrowsePartPage from './pages/partmatcher/BrowsePartPage';
import GuestBrowsePartPage from './pages/partmatcher/GuestBrowsePartPage';
import PartSearchResultPage from './pages/partmatcher/PartSearchResultPage';
import GuestPartSearchPage from './pages/partmatcher/GuestPartSearchPage';
import PartSearchesList from './components/PartSearchesList';
import GeneralRequest from './components/GeneralRequest';
import Messages from './components/Messages';
import ViewMessage from './components/ViewMessages';
import LoginPage from './pages/LoginPage';
import UserProfilePage from './pages/UserProfilePage';
import AddCompanyPage from './pages/AddCompanyPage';
import PublicRoute from './components/PublicRoute';
import ProtectedRoute from './components/ProtectedRoute';
import CompanyProtectedRoute from './components/CompanyProtectedRoute';
import MemidProtectedRoute from './components/MemidProtectedRoute';
import EmailVerifiedRoute from './components/EmailVerifiedRoute';
import VerifyEmailPage from './pages/VerifyEmailPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import AuthStatusListener from './components/AuthStatusListener';

function App() {
  return (
    <Router>
      <div className="App">
        <MyNavbar />
        <AuthStatusListener />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/services/partsnetwork"
              element={<PartsNetworkPage />}
            />
            <Route path="/services/partseller" element={<PartSellerPage />} />
            <Route path="/services/storefront" element={<StorefrontPage />} />
            <Route
              path="/services/testimonials"
              element={<TestimonialsPage />}
            />

            <Route
              path="/forsale/guestattachments"
              element={<GuestAttachmentsForSalePage />}
            />
            <Route
              path="/forsale/guestattachments/:id/:modelnum"
              element={<GuestAttachmentViewPage />}
            />

            <Route
              path="/forsale/guestengines"
              element={<EnginesGuestForSalePage />}
            />
            <Route
              path="/forsale/guestmachines"
              element={<MachinesGuestForSalePage />}
            />
            <Route
              path="/forsale/guesttransmissions"
              element={<TransmissionsGuestForSalePage />}
            />
            <Route
              path="/forsale/guestotheritems"
              element={<OtherItemsGuestForSalePage />}
            />
            <Route
              path="/galleries/thompsonmachinery"
              element={<ThompsonMachineryGalleryPage />}
            />
            <Route
              path="/galleries/thompsontractor"
              element={<ThompsonTractorGalleryPage />}
            />
            <Route
              path="/galleries/yellowstonevalley"
              element={<YellowstoneValleyGalleryPage />}
            />
            <Route
              path="/galleries/wellertractor"
              element={<WellerTractorGalleryPage />}
            />
            <Route
              path="/galleries/boydandsons"
              element={<BoydAndSonsGalleryPage />}
            />
            <Route
              path="/galleries/ironking"
              element={<IronKingGalleryPage />}
            />
            <Route
              path="/galleries/billmiller"
              element={<BillMillerGalleryPage />}
            />
            <Route
              path="/galleries/heavyquip"
              element={<HeavyQuipGalleryPage />}
            />
            <Route
              path="/galleries/altorfer"
              element={<AltorferGalleryPage />}
            />
            <Route
              path="/galleries/carter"
              element={<CarterMachineryGalleryPage />}
            />
            <Route path="/galleries/milton" element={<MiltonGalleryPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route
              path="/legal/privacypolicy"
              element={<PrivacyPolicyPage />}
            />
            <Route path="/legal/termsofuse" element={<TermsOfUsePage />} />
            <Route element={<CompanyProtectedRoute />}>
              <Route
                path="/partmatcher/partsearch"
                element={
                  <EmailVerifiedRoute>
                    <PartSearchPage />
                  </EmailVerifiedRoute>
                }
              />
              <Route
                path="/partmatcher/partsearchresult"
                element={
                  <EmailVerifiedRoute>
                    <PartSearchResultPage />
                  </EmailVerifiedRoute>
                }
              />
              <Route
                path="/partmatcher/generalrequest"
                element={
                  <EmailVerifiedRoute>
                    <GeneralRequest />
                  </EmailVerifiedRoute>
                }
              />
              <Route
                path="/messages"
                element={
                  <EmailVerifiedRoute>
                    <Messages />
                  </EmailVerifiedRoute>
                }
              />
              <Route
                path="/viewmessage/:messageId"
                element={
                  <EmailVerifiedRoute>
                    <ViewMessage />
                  </EmailVerifiedRoute>
                }
              />
              <Route
                path="/forsale/attachments"
                element={
                  <EmailVerifiedRoute>
                    <AttachmentsForSalePage />
                  </EmailVerifiedRoute>
                }
              />
              <Route
                path="/forsale/engines"
                element={
                  <EmailVerifiedRoute>
                    <EnginesForSalePage />
                  </EmailVerifiedRoute>
                }
              />
              <Route
                path="/forsale/machines"
                element={
                  <EmailVerifiedRoute>
                    <MachinesForSalePage />
                  </EmailVerifiedRoute>
                }
              />
              <Route
                path="/forsale/transmissions"
                element={
                  <EmailVerifiedRoute>
                    <TransmissionsForSalePage />
                  </EmailVerifiedRoute>
                }
              />
              <Route
                path="/forsale/otheritems"
                element={
                  <EmailVerifiedRoute>
                    <OtherItemsForSalePage />
                  </EmailVerifiedRoute>
                }
              />
            </Route>

            <Route element={<MemidProtectedRoute />}>
              <Route
                path="/netcomonly/partsearches"
                element={
                  <EmailVerifiedRoute>
                    <PartSearchesList />
                  </EmailVerifiedRoute>
                }
              />
            </Route>

            <Route element={<CompanyProtectedRoute />}>
              <Route
                path="/partmatcher/browseparts"
                element={
                  <EmailVerifiedRoute>
                    <BrowsePartPage />
                  </EmailVerifiedRoute>
                }
              />
            </Route>
            <Route
              path="/partmatcher/guestbrowseparts"
              element={<GuestBrowsePartPage />}
            />
            <Route
              path="/partmatcher/guestpartsearch/:partNumber?"
              element={<GuestPartSearchPage />}
            />
            <Route element={<ProtectedRoute />}>
              <Route path="/profile" element={<UserProfilePage />} />
              <Route path="/create-company" element={<AddCompanyPage />} />
            </Route>
            <Route
              path="/register"
              element={
                <PublicRoute>
                  <UserRegistrationPage />
                </PublicRoute>
              }
            />
            <Route path="/verify-email" element={<VerifyEmailPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <LoginPage />
                </PublicRoute>
              }
            />
          </Routes>
          <MyFooter />
        </div>
      </div>
    </Router>
  );
}

export default App;
