import { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { isTokenExpired } from '../utils/jwtUtils';
import refreshAccessToken from '../utils/refreshToken';

const useGeneralRequest = baseUrl => {
  const [loading, setLoading] = useState(false);
  const [generalRequest, setGeneralRequest] = useState(null);
  const [allGeneralRequests, setAllGeneralRequests] = useState(null);
  const [error, setError] = useState('');
  const { accessToken, refreshToken, updateTokens, logout } = useAuth();

  const sendGeneralRequest = async (
    partNumber,
    manufacturer,
    modelnum,
    comments
  ) => {
    let currentAccessToken = accessToken;

    // Check and refresh the token if it's expired
    if (!currentAccessToken || isTokenExpired(currentAccessToken)) {
      const refreshedTokenData = await refreshAccessToken(
        baseUrl,
        refreshToken,
        updateTokens,
        logout
      );

      if (!refreshedTokenData) {
        // Instead of navigating directly, call the logout function
        logout('Your session has expired. Please log in again.');
        return;
      }
      currentAccessToken = refreshedTokenData.accessToken;
    }

    // Validate comments length
    if (comments && comments.length < 3) {
      setError('Comments must be at least 3 characters long.');
      setGeneralRequest(null);
      return;
    }

    setLoading(true);
    setError('');
    setGeneralRequest(null);

    try {
      const url = new URL(
        `${baseUrl}/api/generalRequest/${encodeURIComponent(comments)}`
      );
      const queryParams = new URLSearchParams();

      if (partNumber) queryParams.append('partNumber', partNumber);
      if (manufacturer) queryParams.append('manufacturer', manufacturer);
      if (modelnum) queryParams.append('modelnum', modelnum);

      url.search = queryParams.toString();

      const response = await fetch(url.toString(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentAccessToken}`
        }
      });

      const data = await response.json();

      if (response.ok) {
        // Assuming the controller sends back a structure like:
        // { status: 200, message: '...', data: { generalRequestId: ... } }
        setGeneralRequest(data.data);
      } else {
        const errorMessage =
          (data.errors && data.errors.length && data.errors[0]) ||
          data.message ||
          'Failed to send request';
        setError(errorMessage);
      }
    } catch (err) {
      setError('Failed to send request');
    } finally {
      setLoading(false);
    }
  };

  const getAllGeneralRequests = async (page = 1, requestsPerPage = 5) => {
    let currentAccessToken = accessToken;

    // Check and refresh the token if it's expired
    if (!currentAccessToken || isTokenExpired(currentAccessToken)) {
      const refreshedTokenData = await refreshAccessToken(
        baseUrl,
        refreshToken,
        updateTokens,
        logout
      );
      if (!refreshedTokenData) {
        logout('Your session has expired. Please log in again.');
        return;
      }
      currentAccessToken = refreshedTokenData.accessToken;
    }

    setLoading(true);
    setError('');

    try {
      const offset = (page - 1) * requestsPerPage;

      const url = new URL(`${baseUrl}/api/generalRequest`);
      const queryParams = new URLSearchParams();
      queryParams.append('limit', requestsPerPage);
      queryParams.append('offset', offset);
      url.search = queryParams.toString();

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentAccessToken}`
        }
      });

      const data = await response.json();

      if (response.ok) {
        setAllGeneralRequests(data.data);
      } else {
        const errorMessage =
          (data.errors && data.errors.length && data.errors[0]) ||
          data.message ||
          'Failed to get general requests';
        setError(errorMessage);
      }
    } catch (err) {
      setError('Failed to get general requests');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    generalRequest,
    allGeneralRequests,
    getAllGeneralRequests,
    error,
    sendGeneralRequest
  };
};

export default useGeneralRequest;
