// src/utils/getFlexBasis.js

/**
 * Determines the flex-basis value based on the current window width and item index.
 *
 * @param {number} windowWidth - The current width of the window.
 * @param {number} index - The index of the current item.
 * @returns {string} - The calculated flex-basis value.
 */
function getFlexBasis(windowWidth, index) {
  if (windowWidth > 1025) {
    // On large screens, display all items in one row (5 columns)
    return 'calc(20% - 5px)';
  }

  if (index < 4) {
    // First four items take 50% width (2 per row)
    return 'calc(50% - 20px)';
  }

  // On small screens, stack items vertically
  return '100%';
}

function getGeneralFlexBasis(windowWidth, index) {
  if (windowWidth > 735) {
    // On large screens, display all items in one row (5 columns)
    return 'calc(20% - 5px)';
  }

  if (index < 4) {
    // First four items take 50% width (2 per row)
    return 'calc(50% - 10px)';
  }

  // On small screens, stack items vertically
  return '100%';
}

function guestFlexBasis(windowWidth, index) {
  if (windowWidth > 800) {
    // On large screens, display all items in one row (5 columns)
    return 'calc(20% - 5px)';
  }

  if (index < 3) {
    // First three items take 50% width (2 per row)
    return 'calc(30% - 10px)';
  }

  // On small screens, stack items vertically
  return '100%';
}

module.exports = {
  getFlexBasis,
  getGeneralFlexBasis,
  guestFlexBasis
};
