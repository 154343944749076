import { useState } from 'react';
import { useAuth } from '../context/AuthContext';

/**
 * A custom hook to send messages to the backend,
 * either as plain text or with attachments (multipart/form-data).
 */
function useSendMessage(baseUrl) {
  const { accessToken } = useAuth();

  // Optional: track sending state or errors here
  const [sending, setSending] = useState(false);
  const [sendError, setSendError] = useState('');

  // 1) Send a plain-text message (like your old POST /api/message)
  const sendMessage = async ({
    recipientCompanyId,
    subject,
    body,
    messageType,
    relatedPartId
  }) => {
    try {
      setSending(true);
      setSendError('');

      const messageData = {
        recipientCompanyId,
        subject,
        body,
        messageType,
        relatedPartId
      };

      const response = await fetch(`${baseUrl}/api/message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(messageData)
      });

      const result = await response.json();
      if (!response.ok) {
        // Extract detailed error messages if available
        const errorMsg = result.errors
          ? result.errors.join(', ')
          : result.message;
        throw new Error(errorMsg || 'Failed to send message');
      }

      return { success: true, data: result.data };
    } catch (error) {
      setSendError(error.message);
      return { success: false, error };
    } finally {
      setSending(false);
    }
  };

  // 2) Send a message with attachments (POST /api/message-with-attachments)
  const sendMessageWithAttachments = async ({
    recipientCompanyId,
    subject,
    body,
    messageType,
    relatedPartId,
    partNumber,
    manuflong,
    condlong,
    files // array of File objects from <input type="file" multiple />
  }) => {
    try {
      setSending(true);
      setSendError('');

      // Build FormData
      const formData = new FormData();
      formData.append('recipientCompanyId', recipientCompanyId);
      formData.append('subject', subject);
      formData.append('body', body);
      formData.append('messageType', messageType);
      if (relatedPartId) formData.append('relatedPartId', relatedPartId);
      if (partNumber) formData.append('partNumber', partNumber);
      if (manuflong) formData.append('manuflong', manuflong);
      if (condlong) formData.append('condlong', condlong);

      // Add attachments
      if (files && files.length > 0) {
        files.forEach(file => {
          formData.append('attachments', file);
        });
      }

      const response = await fetch(`${baseUrl}/api/message-with-attachments`, {
        method: 'POST',
        headers: {
          // DO NOT set 'Content-Type' manually
          Authorization: `Bearer ${accessToken}`
        },
        body: formData
      });

      const result = await response.json();
      if (!response.ok) {
        // Extract detailed error messages if available
        const errorMsg = result.errors
          ? result.errors.join(', ')
          : result.message;
        throw new Error(errorMsg || 'Failed to send message with attachments');
      }

      return { success: true, data: result.data };
    } catch (error) {
      setSendError(error.message);
      return { success: false, error };
    } finally {
      setSending(false);
    }
  };

  return {
    sending,
    sendError,
    sendMessage,
    sendMessageWithAttachments
  };
}

export default useSendMessage;
