import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal, Form, Card } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import useMessage from '../hooks/useMessage';

function ViewMessage() {
  const { messageId } = useParams();
  const { userInfo, accessToken } = useAuth();
  const { userId } = userInfo;

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { currentMessage, fetchMessage, sendReply } = useMessage(baseUrl);

  const [showReplyModal, setShowReplyModal] = useState(false);
  const [replyBody, setReplyBody] = useState('');

  const isSentByUser = currentMessage && currentMessage.senderUserId === userId;

  useEffect(() => {
    if (accessToken) {
      fetchMessage(messageId);
    }
  }, [accessToken, messageId]);

  const handleSendReply = async () => {
    if (!replyBody.trim()) {
      return;
    }

    const updatedMessage = await sendReply(currentMessage.id, replyBody);

    if (updatedMessage) {
      fetchMessage(messageId);
      // Reset the reply body and close the modal
      setReplyBody('');
      setShowReplyModal(false);
    }
  };

  const getReplyToDisplay = () => {
    if (!currentMessage) {
      return ''; // Return an empty string or a default value
    }

    const currentUserId = userId;
    const messageSenderUserId = currentMessage.senderUserId;

    if (messageSenderUserId === currentUserId) {
      // Current user is the sender of the original message
      // Reply is to the recipient company
      const recipientCompanyName =
        currentMessage.RecipientCompany?.name || 'Unknown Recipient';
      return recipientCompanyName;
    }

    // Current user is not the sender of the original message
    // Reply is to the original sender
    const senderUserName = `${currentMessage.SenderUser?.firstName || ''} ${
      currentMessage.SenderUser?.lastName || ''
    }`.trim();

    const senderCompanyName =
      currentMessage.SenderCompany?.name || 'Unknown Sender';

    return `${senderUserName} w/ ${senderCompanyName}`;
  };

  if (!currentMessage) {
    return (
      <div style={{ padding: '20px' }}>
        <p>Loading message...</p>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ marginBottom: '20px' }}>Message Details</h2>
      <Card>
        <Card.Header>
          <strong>{isSentByUser ? 'To:' : 'From:'}</strong>{' '}
          {getReplyToDisplay()}
        </Card.Header>
        <Card.Body>
          <Card.Title>
            <strong>Date:</strong>{' '}
            {new Date(currentMessage.createdAt).toLocaleString()}
          </Card.Title>
          <Card.Text style={{ whiteSpace: 'pre-wrap' }}>
            {currentMessage.body}
          </Card.Text>

          {/* Display Replies */}
          {currentMessage.Replies?.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <h5>Replies:</h5>
              {currentMessage.Replies.map(reply => {
                const senderUserName = `${reply.SenderUser?.firstName || ''} ${
                  reply.SenderUser?.lastName || ''
                }`.trim();
                const senderCompanyName =
                  reply.SenderCompany?.name || 'Unknown Sender';
                return (
                  <Card key={reply.id} className="mb-3">
                    <Card.Header>
                      <strong>
                        {senderUserName} w/ {senderCompanyName}
                      </strong>{' '}
                      - {new Date(reply.createdAt).toLocaleString()}
                    </Card.Header>
                    <Card.Body>
                      <Card.Text style={{ whiteSpace: 'pre-wrap' }}>
                        {reply.body}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          )}

          <Button
            variant="primary"
            onClick={() => setShowReplyModal(true)}
            style={{ marginTop: '20px' }}
          >
            Reply
          </Button>
        </Card.Body>
      </Card>

      {/* Reply Modal */}
      <Modal
        show={showReplyModal}
        onHide={() => {
          setShowReplyModal(false);
          setReplyBody('');
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reply to {getReplyToDisplay()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="replyBody">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={replyBody}
                onChange={e => setReplyBody(e.target.value)}
                placeholder="Enter your reply"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowReplyModal(false);
              setReplyBody('');
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSendReply}
            disabled={!replyBody.trim()}
          >
            Send Reply
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewMessage;
