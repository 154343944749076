import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function MemidProtectedRoute() {
  const { accessToken, userInfo, attemptTokenRefresh } = useAuth();
  const [isChecking, setIsChecking] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkToken = async () => {
      await attemptTokenRefresh(); // Attempt to refresh token if necessary
      setIsChecking(false); // Set checking to false after the attempt
    };

    checkToken();
  }, [attemptTokenRefresh]);

  if (isChecking) {
    // Optionally, you could render a loading spinner or a blank page while checking
    return <div>Loading...</div>;
  }

  if (!accessToken) {
    // If after checking, there's no access token, redirect to login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Check if the user has a memid
  if (!userInfo.memid) {
    // If user is logged in but is not a user connected to a netcom company, redirect to profile page
    return (
      <Navigate
        to="/profile"
        state={{
          from: location,
          message: 'You must be a netcom user to have access to this page'
        }}
        replace
      />
    );
  }

  return <Outlet />; // Render children routes if the token exists and user has a memid
}

export default MemidProtectedRoute;
