import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Spinner,
  Modal,
  Form,
  Tabs,
  Tab,
  DropdownButton,
  Dropdown,
  Pagination
} from 'react-bootstrap';
import { FaStar, FaRegStar, FaSortUp, FaSortDown } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useAuth } from '../context/AuthContext';
import useMessage from '../hooks/useMessage';

function AttachmentPreview({ pic }) {
  // We'll rely on the .s3Url's file extension or the mime type if you store it
  const url = pic.s3Url;
  // Let's do a simple extension check
  const ext = url.split('.').pop().toLowerCase();

  if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'heic'].includes(ext)) {
    // Render image with clickable link to enlarge
    return (
      <div style={{ marginBottom: '10px' }}>
        <button
          type="button"
          onClick={() => window.open(url, '_blank')}
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            cursor: 'pointer'
          }}
          aria-label="View attachment"
        >
          <img src={url} alt="Attachment" style={{ maxWidth: '150px' }} />
        </button>
      </div>
    );
  }

  if (ext === 'pdf') {
    // Render PDF links
    return (
      <div style={{ marginBottom: '10px' }}>
        <a href={url} target="_blank" rel="noreferrer">
          View PDF
        </a>
        {' | '}
        <a href={url} download>
          Download PDF
        </a>
      </div>
    );
  }
  // fallback for other file types
  return (
    <div style={{ marginBottom: '10px' }}>
      <a href={url} target="_blank" rel="noreferrer">
        Download File
      </a>
    </div>
  );
}

// Define PropTypes
AttachmentPreview.propTypes = {
  pic: PropTypes.shape({
    s3Url: PropTypes.string.isRequired
  }).isRequired
};

function Messages() {
  const { userInfo, accessToken } = useAuth();
  const { userId } = userInfo;

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const {
    messages,
    loadingMessages,
    fetchMessages,
    markMessageAsRead,
    markMessageAsUnread,
    toggleStarredStatus,
    sendReply,
    sendReplyWithAttachments,
    getMessageById,
    setCurrentFolder,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    pageSize,
    totalMessages
  } = useMessage(baseUrl);

  const [showModal, setShowModal] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [replyBody, setReplyBody] = useState('');
  const [activeTab, setActiveTab] = useState('inbox');
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [replyAttachments, setReplyAttachments] = useState([]); // Optional: For file attachments

  const allowedFileTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/pdf',
    'image/bmp',
    'image/webp',
    'image/heic'
  ];

  // Define isSentByUser in the component scope for the modal
  const isSentByUser = currentMessage && currentMessage.senderUserId === userId;

  const totalPages = Math.ceil(totalMessages / pageSize);

  const startIndex = totalMessages === 0 ? 0 : (page - 1) * pageSize + 1;
  const endIndex = Math.min(page * pageSize, totalMessages);

  // Fetch messages when the component mounts
  useEffect(() => {
    if (accessToken) {
      fetchMessages();
    }
  }, [accessToken, fetchMessages]);

  const handleReplyFileChange = e => {
    const files = Array.from(e.target.files);
    const invalid = files.filter(f => !allowedFileTypes.includes(f.type));

    if (invalid.length > 0) {
      // eslint-disable-next-line no-alert
      alert(`Invalid file type: ${invalid.map(f => f.name).join(', ')}`);
      e.target.value = null;
      setReplyAttachments([]);
    } else {
      setReplyAttachments(files);
    }
  };

  const handleView = async message => {
    // Fetch the complete message data including PartPictures
    const fetchedMessage = await getMessageById(message.id);
    if (fetchedMessage) {
      setCurrentMessage(fetchedMessage);
      setShowModal(true);

      // Determine the folder based on the active tab
      const folder = activeTab.charAt(0).toUpperCase() + activeTab.slice(1);

      // Mark the message as read
      await markMessageAsRead(message.id, folder);
    } else {
      // eslint-disable-next-line no-alert
      alert('Failed to fetch message details.');
    }
  };

  const handleSendReply = async () => {
    if (!replyBody.trim()) {
      // You can show an error message if the reply is empty
      return;
    }

    // If user selected no attachments, call plain-text `sendReply`
    if (replyAttachments.length === 0) {
      const updatedMessage = await sendReply(currentMessage.id, replyBody);
      if (updatedMessage) {
        // Update currentMessage
        setCurrentMessage(updatedMessage);

        // Reset the reply body and close the modal
        setReplyBody('');
        setReplyAttachments([]);
        setShowReplyModal(false);
      }
    } else {
      // If attachments exist, call `sendReplyWithAttachments`
      const updatedMessage = await sendReplyWithAttachments(
        currentMessage.id,
        replyBody,
        replyAttachments,
        {
          partNumber: 'ABC-123', // If you want
          manuflong: 'CAT',
          condlong: 'Used'
        }
      );
      if (updatedMessage) {
        setCurrentMessage(updatedMessage);
        setShowReplyModal(false);
        setReplyBody('');
        setReplyAttachments([]);
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentMessage(null);
  };

  const handleSelectAll = isChecked => {
    if (isChecked) {
      const messageIds = messages.map(msg => msg.id);
      setSelectedMessages(messageIds);
    } else {
      setSelectedMessages([]);
    }
  };

  const handleSelectMessage = (isChecked, messageId) => {
    if (isChecked) {
      setSelectedMessages(prev => [...prev, messageId]);
    } else {
      setSelectedMessages(prev => prev.filter(id => id !== messageId));
    }
  };

  const handleToggleRead = async message => {
    const folder = activeTab.charAt(0).toUpperCase() + activeTab.slice(1);
    const userMessage = message.UserMessages.find(
      um => um.userId === userId && um.folder === folder
    );

    if (!userMessage) {
      // eslint-disable-next-line no-console
      console.error('UserMessage not found for current folder');
      return;
    }

    if (userMessage.isRead) {
      // Mark as unread
      await markMessageAsUnread(message.id, folder);
    } else {
      // Mark as read
      await markMessageAsRead(message.id, folder);
    }
  };

  const handleToggleStarred = async message => {
    const folder = activeTab.charAt(0).toUpperCase() + activeTab.slice(1);
    await toggleStarredStatus(message.id, folder);
  };

  const moveMessages = async targetFolder => {
    try {
      const response = await fetch(`${baseUrl}/api/messages/move`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          messageIds: selectedMessages,
          targetFolder,
          currentFolder: activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
        })
      });

      if (response.ok) {
        // Refresh messages
        fetchMessages();
        setSelectedMessages([]);
      } else {
        const result = await response.json();
        // eslint-disable-next-line no-console
        console.error('Error moving messages:', result);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error moving messages:', error);
    }
  };

  const moveSingleMessage = async targetFolder => {
    try {
      const response = await fetch(`${baseUrl}/api/messages/move`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          messageIds: [currentMessage.id],
          targetFolder,
          currentFolder: activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
        })
      });

      if (response.ok) {
        // Refresh messages
        fetchMessages();
        setCurrentMessage(null);
        setShowModal(false);
      } else {
        const result = await response.json();
        // eslint-disable-next-line no-console
        console.error('Error moving message:', result);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error moving message:', error);
    }
  };

  const getReplyToDisplay = () => {
    if (!currentMessage) {
      return ''; // Return an empty string or a default value
    }

    const currentUserId = userId;
    const messageSenderUserId = currentMessage.senderUserId;

    if (messageSenderUserId === currentUserId) {
      // Current user is the sender of the original message
      // Reply is to the recipient company
      const recipientCompanyName =
        currentMessage.RecipientCompany?.name || 'Unknown Recipient';
      return recipientCompanyName;
    }

    // Current user is not the sender of the original message
    // Reply is to the original sender
    const senderUserName =
      `${currentMessage.SenderUser?.firstName || ''} ${currentMessage.SenderUser?.lastName || ''}`.trim();
    const senderCompanyName =
      currentMessage.SenderCompany?.name || 'Unknown Sender';
    return `${senderUserName} w/ ${senderCompanyName}`;
  };

  const handleSortDate = () => {
    setSortOrder(prevOrder => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
  };

  if (loadingMessages) {
    return <Spinner animation="border" />;
  }

  return (
    <div style={{ padding: '20px' }}>
      <h2>Messages</h2>
      {/* Flex container for Tabs and Dropdown */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        {/* Tabs aligned to the left */}
        <div>
          <Tabs
            activeKey={activeTab}
            onSelect={k => {
              setActiveTab(k);
              setCurrentFolder(k.charAt(0).toUpperCase() + k.slice(1)); // Capitalize first letter
              setSelectedMessages([]);
              setPage(1); // Reset page to 1 when changing tabs
            }}
            className="mb-0"
          >
            <Tab eventKey="inbox" title="Inbox" />
            <Tab eventKey="saved" title="Saved" />
            <Tab eventKey="archived" title="Archived" />
            <Tab eventKey="sent" title="Sent" />
          </Tabs>
        </div>
        {/* Dropdown Menu for Moving Messages aligned to the right */}
        {activeTab !== 'sent' && (
          <DropdownButton
            id="dropdown-move-messages"
            title="Move Messages"
            variant="secondary"
            disabled={selectedMessages.length === 0}
          >
            <Dropdown.Item onClick={() => moveMessages('Inbox')}>
              Move to Inbox
            </Dropdown.Item>
            <Dropdown.Item onClick={() => moveMessages('Saved')}>
              Move to Saved
            </Dropdown.Item>
            <Dropdown.Item onClick={() => moveMessages('Archived')}>
              Move to Archived
            </Dropdown.Item>
          </DropdownButton>
        )}
      </div>

      {/* Message Table */}
      <div>
        {/* Inbox Tab */}
        {activeTab === 'inbox' && (
          <Tab.Content>
            <Tab.Pane eventKey="inbox" active>
              {messages && messages.length === 0 ? (
                <p>No messages to display.</p>
              ) : (
                <>
                  <Table striped bordered hover>
                    {/* Table headers */}
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={e => handleSelectAll(e.target.checked)}
                            checked={
                              messages.length > 0 &&
                              selectedMessages.length === messages.length
                            }
                          />
                        </th>
                        <th>From</th>
                        <th>Subject</th>
                        <th
                          onClick={handleSortDate}
                          style={{ cursor: 'pointer' }}
                        >
                          Date{' '}
                          {sortOrder === 'ASC' ? <FaSortUp /> : <FaSortDown />}
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {messages.map(message => {
                        const messageIsFromUser =
                          message.senderUserId === userId;
                        let fromDisplay;

                        if (!messageIsFromUser) {
                          // Message is from someone else
                          const senderUserName =
                            `${message.SenderUser?.firstName || ''} ${message.SenderUser?.lastName || ''}`.trim();
                          const senderCompanyName =
                            message.SenderCompany?.name || 'Unknown Sender';
                          fromDisplay = `From: ${senderUserName} w/ ${senderCompanyName}`;
                        } else {
                          // Message is sent by user but has replies from others
                          const repliesFromOthers =
                            message.Replies?.filter(
                              reply => reply.senderUserId !== userId
                            ) || [];
                          if (repliesFromOthers.length > 0) {
                            const lastReplyFromOther =
                              repliesFromOthers[repliesFromOthers.length - 1];
                            const senderUserName =
                              `${lastReplyFromOther.SenderUser?.firstName || ''} ${lastReplyFromOther.SenderUser?.lastName || ''}`.trim();
                            const senderCompanyName =
                              lastReplyFromOther.SenderCompany?.name ||
                              'Unknown Sender';
                            fromDisplay = `From: ${senderUserName} w/ ${senderCompanyName}`;
                          } else {
                            // No replies from others, default to recipient company
                            const recipientCompanyName =
                              message.RecipientCompany?.name ||
                              'Unknown Recipient';
                            fromDisplay = `From: ${recipientCompanyName}`;
                          }
                        }

                        const hasReplies = message?.Replies?.length > 0;

                        const folder =
                          activeTab.charAt(0).toUpperCase() +
                          activeTab.slice(1);
                        const userMessage = message.UserMessages.find(
                          um => um.userId === userId && um.folder === folder
                        );

                        return (
                          <tr
                            key={message.id}
                            style={{
                              fontWeight: userMessage?.isRead
                                ? 'normal'
                                : 'bold'
                            }}
                          >
                            <td>
                              <input
                                type="checkbox"
                                onClick={e => e.stopPropagation()}
                                checked={selectedMessages.includes(message.id)}
                                onChange={e =>
                                  handleSelectMessage(
                                    e.target.checked,
                                    message.id
                                  )
                                }
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                onClick={e => {
                                  e.stopPropagation(); // Prevent the click from propagating to the parent
                                  handleToggleStarred(message);
                                }}
                                style={{
                                  cursor: 'pointer',
                                  marginRight: '5px',
                                  background: 'none',
                                  border: 'none',
                                  padding: '0'
                                }}
                                aria-label={
                                  userMessage?.isStarred
                                    ? 'Unstar message'
                                    : 'Star message'
                                }
                              >
                                {userMessage?.isStarred ? (
                                  <FaStar color="gold" />
                                ) : (
                                  <FaRegStar color="grey" />
                                )}
                              </button>
                              {/* From Company Name Button */}
                              <button
                                type="button"
                                onClick={() => handleView(message)}
                                style={{
                                  cursor: 'pointer',
                                  background: 'none',
                                  border: 'none',
                                  fontWeight: userMessage?.isRead
                                    ? 'normal'
                                    : 'bold',
                                  padding: '0',
                                  margin: '0',
                                  color: 'inherit',
                                  textAlign: 'left',
                                  textDecoration: 'none'
                                }}
                                className="from-button" // Optional: For additional styling
                              >
                                {`${fromDisplay}`}
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() => handleView(message)}
                                type="button"
                                style={{
                                  cursor: 'pointer',
                                  background: 'none',
                                  border: 'none',
                                  padding: 0,
                                  textAlign: 'left'
                                }}
                              >
                                {message.subject}
                                {hasReplies && (
                                  <span className="reply-indicator">
                                    {' '}
                                    (replied)
                                  </span>
                                )}
                              </button>
                            </td>
                            <td>
                              <button
                                type="button"
                                onClick={() => handleView(message)}
                                style={{
                                  cursor: 'pointer',
                                  background: 'none',
                                  border: 'none',
                                  padding: 0,
                                  textAlign: 'left'
                                }}
                              >
                                {new Date(message.createdAt).toLocaleString()}
                              </button>
                            </td>
                            <td>
                              <Button
                                variant="secondary"
                                onClick={e => {
                                  e.stopPropagation(); // Prevent click from reaching parent
                                  handleToggleRead(message);
                                }}
                              >
                                {userMessage?.isRead
                                  ? 'Mark as Unread'
                                  : 'Mark as Read'}
                              </Button>{' '}
                              <Button
                                variant="primary"
                                onClick={e => {
                                  e.stopPropagation(); // Prevent click from reaching parent
                                  handleView(message);
                                }}
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  {totalPages > 1 && (
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                        disabled={page === 1}
                      />
                      <Pagination.Item active>Page: {page}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => setPage(prev => prev + 1)}
                        disabled={page === totalPages}
                      />
                    </Pagination>
                  )}

                  <div>
                    <p>
                      Showing {startIndex}-{endIndex} of {totalMessages} total
                      messages
                    </p>
                  </div>
                </>
              )}
            </Tab.Pane>
          </Tab.Content>
        )}

        {/* Saved Tab */}
        {activeTab === 'saved' && (
          <Tab.Content>
            <Tab.Pane eventKey="saved" active>
              {messages && messages.length === 0 ? (
                <p>No messages to display.</p>
              ) : (
                <>
                  <Table striped bordered hover>
                    {/* Table headers */}
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={e => handleSelectAll(e.target.checked)}
                            checked={
                              messages.length > 0 &&
                              selectedMessages.length === messages.length
                            }
                          />
                        </th>
                        <th>From / To</th>
                        <th>Subject</th>
                        <th
                          onClick={handleSortDate}
                          style={{ cursor: 'pointer' }}
                        >
                          Date{' '}
                          {sortOrder === 'ASC' ? <FaSortUp /> : <FaSortDown />}
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {messages.map(message => {
                        const messageIsFromUser =
                          message.senderUserId === userId;
                        let fromDisplay;

                        if (!messageIsFromUser) {
                          // Message is from someone else
                          const senderUserName =
                            `${message.SenderUser?.firstName || ''} ${message.SenderUser?.lastName || ''}`.trim();
                          const senderCompanyName =
                            message.SenderCompany?.name || 'Unknown Sender';
                          fromDisplay = `From: ${senderUserName} w/ ${senderCompanyName}`;
                        } else {
                          // Message is sent by user but has replies from others
                          const repliesFromOthers =
                            message.Replies?.filter(
                              reply => reply.senderUserId !== userId
                            ) || [];
                          if (repliesFromOthers.length > 0) {
                            const lastReplyFromOther =
                              repliesFromOthers[repliesFromOthers.length - 1];
                            const senderUserName =
                              `${lastReplyFromOther.SenderUser?.firstName || ''} ${lastReplyFromOther.SenderUser?.lastName || ''}`.trim();
                            const senderCompanyName =
                              lastReplyFromOther.SenderCompany?.name ||
                              'Unknown Sender';
                            fromDisplay = `From: ${senderUserName} w/ ${senderCompanyName}`;
                          } else {
                            // No replies from others, default to recipient company
                            const recipientCompanyName =
                              message.RecipientCompany?.name ||
                              'Unknown Recipient';
                            fromDisplay = `From: ${recipientCompanyName}`;
                          }
                        }

                        const hasReplies = message?.Replies?.length > 0;

                        const folder =
                          activeTab.charAt(0).toUpperCase() +
                          activeTab.slice(1);
                        const userMessage = message.UserMessages.find(
                          um => um.userId === userId && um.folder === folder
                        );

                        return (
                          <tr
                            key={message.id}
                            style={{
                              fontWeight: userMessage?.isRead
                                ? 'normal'
                                : 'bold'
                            }}
                          >
                            <td>
                              <input
                                type="checkbox"
                                onClick={e => e.stopPropagation()}
                                checked={selectedMessages.includes(message.id)}
                                onChange={e =>
                                  handleSelectMessage(
                                    e.target.checked,
                                    message.id
                                  )
                                }
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                onClick={e => {
                                  e.stopPropagation(); // Prevent the click from propagating to the parent
                                  handleToggleStarred(message);
                                }}
                                style={{
                                  cursor: 'pointer',
                                  marginRight: '5px',
                                  background: 'none',
                                  border: 'none',
                                  padding: '0'
                                }}
                                aria-label={
                                  userMessage?.isStarred
                                    ? 'Unstar message'
                                    : 'Star message'
                                }
                              >
                                {userMessage?.isStarred ? (
                                  <FaStar color="gold" />
                                ) : (
                                  <FaRegStar color="grey" />
                                )}
                              </button>
                              <button
                                type="button"
                                onClick={() => handleView(message)}
                                style={{
                                  cursor: 'pointer',
                                  background: 'none',
                                  border: 'none',
                                  fontWeight: userMessage?.isRead
                                    ? 'normal'
                                    : 'bold',
                                  padding: '0',
                                  margin: '0',
                                  color: 'inherit',
                                  textAlign: 'left',
                                  textDecoration: 'none'
                                }}
                                className="from-button" // Optional: For additional styling
                              >
                                {`${fromDisplay}`}
                              </button>
                            </td>
                            <td
                              onClick={() => handleView(message)}
                              style={{ cursor: 'pointer' }}
                            >
                              {message.subject}
                              {hasReplies && (
                                <span className="reply-indicator">
                                  {' '}
                                  (replied)
                                </span>
                              )}
                            </td>
                            <td
                              onClick={() => handleView(message)}
                              style={{ cursor: 'pointer' }}
                            >
                              {new Date(message.createdAt).toLocaleString()}
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                onClick={() => handleView(message)}
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  {totalPages > 1 && (
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                        disabled={page === 1}
                      />
                      <Pagination.Item active>Page: {page}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => setPage(prev => prev + 1)}
                        disabled={page === totalPages}
                      />
                    </Pagination>
                  )}

                  <div>
                    <p>
                      Showing {startIndex}-{endIndex} of {totalMessages} total
                      messages
                    </p>
                  </div>
                </>
              )}
            </Tab.Pane>
          </Tab.Content>
        )}

        {/* Archived Tab */}
        {activeTab === 'archived' && (
          <Tab.Content>
            <Tab.Pane eventKey="archived" active>
              {messages && messages.length === 0 ? (
                <p>No messages to display.</p>
              ) : (
                <>
                  <Table striped bordered hover>
                    {/* Table headers */}
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={e => handleSelectAll(e.target.checked)}
                            checked={
                              messages.length > 0 &&
                              selectedMessages.length === messages.length
                            }
                          />
                        </th>
                        <th>From / To</th>
                        <th>Subject</th>
                        <th
                          onClick={handleSortDate}
                          style={{ cursor: 'pointer' }}
                        >
                          Date{' '}
                          {sortOrder === 'ASC' ? <FaSortUp /> : <FaSortDown />}
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {messages.map(message => {
                        const messageIsFromUser =
                          message.senderUserId === userId;
                        let fromDisplay;

                        if (!messageIsFromUser) {
                          // Message is from someone else
                          const senderUserName =
                            `${message.SenderUser?.firstName || ''} ${message.SenderUser?.lastName || ''}`.trim();
                          const senderCompanyName =
                            message.SenderCompany?.name || 'Unknown Sender';
                          fromDisplay = `From: ${senderUserName} w/ ${senderCompanyName}`;
                        } else {
                          // Message is sent by user but has replies from others
                          const repliesFromOthers =
                            message.Replies?.filter(
                              reply => reply.senderUserId !== userId
                            ) || [];
                          if (repliesFromOthers.length > 0) {
                            const lastReplyFromOther =
                              repliesFromOthers[repliesFromOthers.length - 1];
                            const senderUserName =
                              `${lastReplyFromOther.SenderUser?.firstName || ''} ${lastReplyFromOther.SenderUser?.lastName || ''}`.trim();
                            const senderCompanyName =
                              lastReplyFromOther.SenderCompany?.name ||
                              'Unknown Sender';
                            fromDisplay = `From: ${senderUserName} w/ ${senderCompanyName}`;
                          } else {
                            // No replies from others, default to recipient company
                            const recipientCompanyName =
                              message.RecipientCompany?.name ||
                              'Unknown Recipient';
                            fromDisplay = `From: ${recipientCompanyName}`;
                          }
                        }

                        const hasReplies = message?.Replies?.length > 0;

                        const folder =
                          activeTab.charAt(0).toUpperCase() +
                          activeTab.slice(1);
                        const userMessage = message.UserMessages.find(
                          um => um.userId === userId && um.folder === folder
                        );

                        return (
                          <tr
                            key={message.id}
                            style={{
                              fontWeight: userMessage?.isRead
                                ? 'normal'
                                : 'bold'
                            }}
                          >
                            <td>
                              <input
                                type="checkbox"
                                onClick={e => e.stopPropagation()}
                                checked={selectedMessages.includes(message.id)}
                                onChange={e =>
                                  handleSelectMessage(
                                    e.target.checked,
                                    message.id
                                  )
                                }
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                onClick={e => {
                                  e.stopPropagation(); // Prevent the click from propagating to the parent
                                  handleToggleStarred(message);
                                }}
                                style={{
                                  cursor: 'pointer',
                                  marginRight: '5px',
                                  background: 'none',
                                  border: 'none',
                                  padding: '0'
                                }}
                                aria-label={
                                  userMessage?.isStarred
                                    ? 'Unstar message'
                                    : 'Star message'
                                }
                              >
                                {userMessage?.isStarred ? (
                                  <FaStar color="gold" />
                                ) : (
                                  <FaRegStar color="grey" />
                                )}
                              </button>
                              <button
                                type="button"
                                onClick={() => handleView(message)}
                                style={{
                                  cursor: 'pointer',
                                  background: 'none',
                                  border: 'none',
                                  fontWeight: userMessage?.isRead
                                    ? 'normal'
                                    : 'bold',
                                  padding: '0',
                                  margin: '0',
                                  color: 'inherit',
                                  textAlign: 'left',
                                  textDecoration: 'none'
                                }}
                                className="from-button" // Optional: For additional styling
                              >
                                {`${fromDisplay}`}
                              </button>
                            </td>
                            <td
                              onClick={() => handleView(message)}
                              style={{ cursor: 'pointer' }}
                            >
                              {message.subject}
                              {hasReplies && (
                                <span className="reply-indicator">
                                  {' '}
                                  (replied)
                                </span>
                              )}
                            </td>
                            <td
                              onClick={() => handleView(message)}
                              style={{ cursor: 'pointer' }}
                            >
                              {new Date(message.createdAt).toLocaleString()}
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                onClick={() => handleView(message)}
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  {totalPages > 1 && (
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                        disabled={page === 1}
                      />
                      <Pagination.Item active>Page: {page}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => setPage(prev => prev + 1)}
                        disabled={page === totalPages}
                      />
                    </Pagination>
                  )}

                  <div>
                    <p>
                      Showing {startIndex}-{endIndex} of {totalMessages} total
                      messages
                    </p>
                  </div>
                </>
              )}
            </Tab.Pane>
          </Tab.Content>
        )}

        {/* Sent Tab */}
        {activeTab === 'sent' && (
          <Tab.Content>
            <Tab.Pane eventKey="sent" active>
              {messages && messages.length === 0 ? (
                <p>No messages to display.</p>
              ) : (
                <>
                  <Table striped bordered hover>
                    {/* Table headers */}
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={e => handleSelectAll(e.target.checked)}
                            checked={
                              messages.length > 0 &&
                              selectedMessages.length === messages.length
                            }
                          />
                        </th>
                        <th>To</th>
                        <th>Subject</th>
                        <th
                          onClick={handleSortDate}
                          style={{ cursor: 'pointer' }}
                        >
                          Date{' '}
                          {sortOrder === 'ASC' ? <FaSortUp /> : <FaSortDown />}
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {messages.map(message => {
                        const hasRepliesFromOthers = message.Replies?.some(
                          reply => reply.senderUserId !== userId
                        );

                        const userMessage = message.UserMessages?.find(
                          um =>
                            um.userId === userId &&
                            um.folder ===
                              activeTab.charAt(0).toUpperCase() +
                                activeTab.slice(1)
                        );

                        const isRead = userMessage ? userMessage.isRead : true;
                        const isStarred = userMessage
                          ? userMessage.isStarred
                          : false;

                        // Determine recipient display
                        let recipientDisplay;

                        if (message?.Replies?.length > 0) {
                          // Get the last reply made by the current user
                          const lastReplyByUser = message.Replies.filter(
                            reply => reply.senderUserId === userId
                          ).slice(-1)[0];

                          if (lastReplyByUser && lastReplyByUser.ReplyToUser) {
                            const replyToUserName =
                              `${lastReplyByUser.ReplyToUser.firstName} ${lastReplyByUser.ReplyToUser.lastName}`.trim();
                            const replyToCompanyName =
                              lastReplyByUser.ReplyToCompany?.name ||
                              'Unknown Recipient';
                            recipientDisplay = `To: ${replyToUserName} w/ ${replyToCompanyName}`;
                          } else {
                            // Fallback to RecipientCompany
                            const recipientCompanyName =
                              message.RecipientCompany?.name ||
                              'Unknown Recipient';
                            recipientDisplay = `To: ${recipientCompanyName}`;
                          }
                        } else {
                          // For original messages sent by user
                          const recipientCompanyName =
                            message.RecipientCompany?.name ||
                            'Unknown Recipient';
                          recipientDisplay = `To: ${recipientCompanyName}`;
                        }

                        return (
                          <tr
                            key={message.id}
                            style={{
                              fontWeight: isRead ? 'normal' : 'bold'
                            }}
                          >
                            <td>
                              <input
                                type="checkbox"
                                onClick={e => e.stopPropagation()}
                                checked={selectedMessages.includes(message.id)}
                                onChange={e =>
                                  handleSelectMessage(
                                    e.target.checked,
                                    message.id
                                  )
                                }
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                onClick={e => {
                                  e.stopPropagation(); // Prevent the click from propagating to the parent
                                  handleToggleStarred(message);
                                }}
                                style={{
                                  cursor: 'pointer',
                                  marginRight: '5px',
                                  background: 'none',
                                  border: 'none',
                                  padding: '0'
                                }}
                                aria-label={
                                  isStarred ? 'Unstar message' : 'Star message'
                                }
                              >
                                {isStarred ? (
                                  <FaStar color="gold" />
                                ) : (
                                  <FaRegStar color="grey" />
                                )}
                              </button>
                              <button
                                type="button"
                                onClick={() => handleView(message)}
                                style={{
                                  cursor: 'pointer',
                                  background: 'none',
                                  border: 'none',
                                  fontWeight: userMessage?.isRead
                                    ? 'normal'
                                    : 'bold',
                                  padding: '0',
                                  margin: '0',
                                  color: 'inherit',
                                  textAlign: 'left',
                                  textDecoration: 'none'
                                }}
                                className="from-button" // Optional: For additional styling
                              >
                                {recipientDisplay}
                              </button>
                            </td>
                            <td
                              onClick={() => handleView(message)}
                              style={{ cursor: 'pointer' }}
                            >
                              {message.subject}
                              {hasRepliesFromOthers && (
                                <span className="reply-indicator">
                                  {' '}
                                  (replied)
                                </span>
                              )}
                            </td>
                            <td
                              onClick={() => handleView(message)}
                              style={{ cursor: 'pointer' }}
                            >
                              {new Date(message.createdAt).toLocaleString()}
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                onClick={() => handleView(message)}
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  {totalPages > 1 && (
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                        disabled={page === 1}
                      />
                      <Pagination.Item active>Page: {page}</Pagination.Item>
                      <Pagination.Next
                        onClick={() => setPage(prev => prev + 1)}
                        disabled={page === totalPages}
                      />
                    </Pagination>
                  )}

                  <div>
                    <p>
                      Showing {startIndex}-{endIndex} of {totalMessages} total
                      messages
                    </p>
                  </div>
                </>
              )}
            </Tab.Pane>
          </Tab.Content>
        )}
      </div>

      {/* Message View Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{currentMessage?.subject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>{isSentByUser ? 'To:' : 'From:'}</strong>{' '}
            {isSentByUser
              ? (() => {
                  let recipientDisplay;
                  if (currentMessage?.Replies?.length > 0) {
                    // Get the last reply made by the current user
                    const lastReplyByUser = currentMessage.Replies.filter(
                      reply => reply.senderUserId === userId
                    ).slice(-1)[0];

                    if (lastReplyByUser && lastReplyByUser.ReplyToUser) {
                      const replyToUserName =
                        `${lastReplyByUser.ReplyToUser.firstName} ${lastReplyByUser.ReplyToUser.lastName}`.trim();
                      const replyToCompanyName =
                        lastReplyByUser.ReplyToCompany?.name ||
                        'Unknown Recipient';
                      recipientDisplay = `${replyToUserName} w/ ${replyToCompanyName}`;
                    } else {
                      const recipientCompanyName =
                        currentMessage.RecipientCompany?.name ||
                        'Unknown Recipient';
                      recipientDisplay = recipientCompanyName;
                    }
                  } else {
                    const recipientCompanyName =
                      currentMessage.RecipientCompany?.name ||
                      'Unknown Recipient';
                    recipientDisplay = recipientCompanyName;
                  }
                  return recipientDisplay;
                })()
              : (() => {
                  const senderUserName =
                    `${currentMessage?.SenderUser?.firstName || ''} ${currentMessage?.SenderUser?.lastName || ''}`.trim();
                  const senderCompanyName =
                    currentMessage?.SenderCompany?.name || 'Unknown Sender';
                  return `${senderUserName} w/ ${senderCompanyName}`;
                })()}
          </p>
          <p>
            <strong>Date:</strong>{' '}
            {new Date(currentMessage?.createdAt).toLocaleString()}
          </p>
          <hr />

          {/* Display the main message body */}
          <p style={{ whiteSpace: 'pre-wrap' }}>{currentMessage?.body}</p>

          {/* If the messageType is "part", let's show top-level attachments */}
          {currentMessage?.messageType === 'part' &&
            (() => {
              // Filter to only top-level attachments (i.e., reply ID is null)
              const topLevelAttachments =
                currentMessage.PartPictures?.filter(
                  pic => !pic.messageReplyId
                ) || [];

              if (topLevelAttachments.length === 0) {
                return null;
              }

              return (
                <div style={{ marginTop: '20px' }}>
                  <h5>Attachments:</h5>
                  {topLevelAttachments.map(pic => (
                    <AttachmentPreview key={pic.id} pic={pic} />
                  ))}
                </div>
              );
            })()}

          {/* Display Replies */}
          {currentMessage?.Replies?.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <h5>Replies:</h5>
              {currentMessage.Replies.map(reply => {
                const senderUserName =
                  `${reply.SenderUser?.firstName || ''} ${reply.SenderUser?.lastName || ''}`.trim();
                const senderCompanyName =
                  reply.SenderCompany?.name || 'Unknown Sender';
                return (
                  <div key={reply.id} style={{ marginBottom: '15px' }}>
                    <p>
                      <strong>
                        {senderUserName} w/ {senderCompanyName}
                      </strong>{' '}
                      - {new Date(reply.createdAt).toLocaleString()}
                    </p>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{reply.body}</p>

                    {reply.PartPictures?.length > 0 && (
                      <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                        <h6>Reply Attachments:</h6>
                        {reply.PartPictures.map(pic => (
                          <AttachmentPreview key={pic.id} pic={pic} />
                        ))}
                      </div>
                    )}

                    <hr />
                  </div>
                );
              })}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowReplyModal(true)}>
            Reply
          </Button>
          {activeTab !== 'sent' && (
            <DropdownButton id="dropdown-move" title="Move">
              <Dropdown.Item onClick={() => moveSingleMessage('Inbox')}>
                Move to Inbox
              </Dropdown.Item>
              <Dropdown.Item onClick={() => moveSingleMessage('Saved')}>
                Move to Saved
              </Dropdown.Item>
              <Dropdown.Item onClick={() => moveSingleMessage('Archived')}>
                Move to Archived
              </Dropdown.Item>
            </DropdownButton>
          )}
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Reply Modal */}
      <Modal
        show={showReplyModal}
        onHide={() => setShowReplyModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reply to {getReplyToDisplay()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="replyBody">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={replyBody}
                onChange={e => setReplyBody(e.target.value)}
                placeholder="Enter your reply"
              />
            </Form.Group>

            <Form.Group controlId="replyAttachments" className="mt-3">
              <Form.Label>Attachments (Images/PDF/etc.)</Form.Label>
              <Form.Control
                type="file"
                multiple
                accept=".jpg,.jpeg,.png,.gif,.pdf,.bmp,.webp,.heic"
                onChange={handleReplyFileChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowReplyModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSendReply}>
            Send Reply
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Messages;
