import React from 'react';
import { NavLink as RRNavLink, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import '../App.css'; // Import the global CSS file
import { useAuth } from '../context/AuthContext'; // Import useAuth hook

function MyNavbar() {
  const { accessToken, userInfo, logout } = useAuth(); // Destructure setLogoutMessage from context
  const isLoggedIn = !!accessToken; // Directly compute isLoggedIn based on accessToken
  const location = useLocation();
  // const isServicesActive = location.pathname.startsWith('/services');
  // const isGalleriesActive = location.pathname.startsWith('/galleries');
  const isForSaleActive = location.pathname.startsWith('/forsale');
  const isPartSearchActive = location.pathname.startsWith('/partmatcher');
  const isNetComOnlyActive = location.pathname.startsWith('/netcomonly');

  const targetPartSearchPath = isLoggedIn
    ? '/partmatcher/partsearch'
    : '/partmatcher/guestpartsearch';
  const targetForSalePath = isLoggedIn
    ? '/forsale/attachments'
    : '/forsale/guestattachments';
  const targetEnginePath = isLoggedIn
    ? '/forsale/engines'
    : '/forsale/guestengines';
  const targetTransmissionsPath = isLoggedIn
    ? '/forsale/transmissions'
    : '/forsale/guesttransmissions';
  const targetOtherItemsPath = isLoggedIn
    ? '/forsale/otheritems'
    : '/forsale/guestotheritems';
  const targetMachinesPath = isLoggedIn
    ? '/forsale/machines'
    : '/forsale/guestmachines';
  const targetBrowsePartPath = isLoggedIn
    ? '/partmatcher/browseparts'
    : '/partmatcher/guestbrowseparts';
  const handlePartSearchClick = e => {
    if (location.pathname === targetPartSearchPath) {
      e.preventDefault();
      window.location.reload();
    }
  };
  // Handler for the logout action
  const handleLogout = () => {
    logout(); // You might not need to pass navigate and setLogoutMessage if the logout function itself handles redirection and messages
  };

  return (
    <Navbar bg="light" expand="lg" fixed="top" className="px-3">
      <Navbar.Brand href="/">
        <img
          id="navbar-logo"
          src="/images/constructionpartslogo.png"
          alt="NetCom Services Logo"
          height="30"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link as={RRNavLink} to="/">
            Home
          </Nav.Link>
          <NavDropdown
            title="Part Search"
            id="navbarDropdownPartSearch"
            className={isPartSearchActive ? 'active-dropdown' : ''}
          >
            <NavDropdown.Item
              as={RRNavLink}
              to={targetPartSearchPath}
              onClick={handlePartSearchClick}
            >
              Part Search
            </NavDropdown.Item>
            <NavDropdown.Item href="/partmatcher/partsearchresult">
              Part Search Results
            </NavDropdown.Item>
            <NavDropdown.Item as={RRNavLink} to={targetBrowsePartPath}>
              Browse Parts
            </NavDropdown.Item>
            <NavDropdown.Item as={RRNavLink} to="/partmatcher/generalrequest">
              General Request
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title="For Sale"
            id="navbarDropdownForSale"
            className={isForSaleActive ? 'active-dropdown' : ''}
          >
            <NavDropdown.Item as={RRNavLink} to={targetForSalePath}>
              Attachments
            </NavDropdown.Item>
            <NavDropdown.Item as={RRNavLink} to={targetEnginePath}>
              Engines
            </NavDropdown.Item>
            <NavDropdown.Item as={RRNavLink} to={targetMachinesPath}>
              Machines
            </NavDropdown.Item>
            <NavDropdown.Item as={RRNavLink} to={targetTransmissionsPath}>
              Transmissions
            </NavDropdown.Item>
            <NavDropdown.Item as={RRNavLink} to={targetOtherItemsPath}>
              Other Items
            </NavDropdown.Item>
          </NavDropdown>

          {isLoggedIn && (
            <Nav.Link as={RRNavLink} to="/messages">
              Messages
            </Nav.Link>
          )}

          {isLoggedIn && userInfo?.memid && (
            <NavDropdown
              title="NetCom Only"
              id="navbarDropdownPartSearch"
              className={isNetComOnlyActive ? 'active-dropdown' : ''}
            >
              <NavDropdown.Item href="/netcomonly/partsearches">
                Part Search List
              </NavDropdown.Item>
            </NavDropdown>
          )}

          {/* <NavDropdown
            title="Services"
            id="navbarDropdownServices"
            className={isServicesActive ? 'active-dropdown' : ''}
          >
            <NavDropdown.Item href="/services/partsnetwork">
              Parts Network
            </NavDropdown.Item>
            <NavDropdown.Item href="/services/partseller">
              Part Seller
            </NavDropdown.Item>
            <NavDropdown.Item href="/services/storefront">
              Marketplace Solution
            </NavDropdown.Item>
            <NavDropdown.Item href="/services/testimonials">
              Testimonials
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title="Galleries"
            id="navbarDropdownGalleries"
            className={isGalleriesActive ? 'active-dropdown' : ''}
          >
            <NavDropdown.Item href="/galleries/altorfer">
              Altorfer Cat
            </NavDropdown.Item>
            <NavDropdown.Item href="/galleries/billmiller">
              Bill Miller Equipment Sales
            </NavDropdown.Item>
            <NavDropdown.Item href="/galleries/boydandsons">
              Boyd and Sons Machinery
            </NavDropdown.Item>
            <NavDropdown.Item href="/galleries/carter">
              Carter Machinery
            </NavDropdown.Item>
            <NavDropdown.Item href="/galleries/heavyquip">
              Heavy Quip Inc. dba Diesel Sales
            </NavDropdown.Item>
            <NavDropdown.Item href="/galleries/ironking">
              Iron King Inc.
            </NavDropdown.Item>
            <NavDropdown.Item href="/galleries/milton">
              Milton Cat
            </NavDropdown.Item>
            <NavDropdown.Item href="/galleries/thompsonmachinery">
              Thompson Machinery
            </NavDropdown.Item>
            <NavDropdown.Item href="/galleries/thompsontractor">
              Thompson Tractor
            </NavDropdown.Item>
            <NavDropdown.Item href="/galleries/wellertractor">
              Weller Tractor Salvage
            </NavDropdown.Item>
            <NavDropdown.Item href="/galleries/yellowstonevalley">
              Yellowstone Valley Parts & Equipment
            </NavDropdown.Item>
          </NavDropdown> */}

          <Nav.Link as={RRNavLink} to="/contact">
            Contact Us
          </Nav.Link>

          {isLoggedIn && (
            <Nav.Link as={RRNavLink} to="/profile">
              My Account
            </Nav.Link>
          )}

          {isLoggedIn ? (
            <Nav.Link as="button" onClick={handleLogout}>
              Logout
            </Nav.Link>
          ) : (
            <Nav.Link as={RRNavLink} to="/login">
              Log In
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MyNavbar;
