import React, { useState, useEffect } from 'react';
import manufListHook from '@netcom-network-v2/shared/src/hooks/manufListHook';
import Table from 'react-bootstrap/Table'; // or your preferred table component
import { getGeneralFlexBasis } from '../utils/getFlexBasis';
import useGeneralRequest from '../hooks/useGeneralRequest';

function GeneralRequest() {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  // Variables in use
  const [partNumber, setPartNumber] = useState('');
  const [manufacturer, setManufacturer] = useState('All'); // State for condition
  const [modelnum, setModelnum] = useState(''); // State for condition
  const [comments, setComments] = useState(''); // State for condition
  const [focusedField, setFocusedField] = useState(null); // Track which field is focused
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const requestsPerPage = 10;

  const {
    generalRequest,
    error,
    sendGeneralRequest,
    allGeneralRequests,
    getAllGeneralRequests
  } = useGeneralRequest(baseUrl);
  const { manufList } = manufListHook(baseUrl);

  // // State for partfinder users
  // const [partfinderUserApproved, setPartfinderUserApproved] = useState('no');
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getAllGeneralRequests(currentPage, requestsPerPage);
  }, [currentPage, requestsPerPage]);

  const handleInputChange = e => {
    const formattedInput = e.target.value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '');
    setPartNumber(formattedInput);
  };

  const handleModelNumberChange = e => {
    setModelnum(e.target.value);
  };
  const handleManufacturerChange = e => {
    setManufacturer(e.target.value);
  };

  const handleCommentChange = e => {
    setComments(e.target.value);
  };

  const handleSearch = async () => {
    if (comments.trim().length < 3) {
      setErrorMessage('Comments must be at least 3 characters long.');
      return;
    }
    // Clear any existing error message
    setErrorMessage('');

    await sendGeneralRequest(partNumber, manufacturer, modelnum, comments);
    setComments('');
    setPartNumber('');
    setManufacturer('');
    setModelnum('');

    setCurrentPage(1);
    await getAllGeneralRequests();
  };

  const totalRequests = allGeneralRequests ? allGeneralRequests.count : 0;
  const totalPages = Math.ceil(totalRequests / requestsPerPage);

  // Pagination controls handler
  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
    getAllGeneralRequests(pageNumber, requestsPerPage);
  };

  // Create an array of page numbers for controls
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i += 1) {
    pageNumbers.push(i);
  }

  const getDisplayedPages = () => {
    if (totalPages <= 3) {
      return [...Array(totalPages)].map((_, i) => i + 1);
    }
    if (currentPage <= 2) {
      return [1, 2, 3];
    }
    if (currentPage >= totalPages - 1) {
      return [totalPages - 2, totalPages - 1, totalPages];
    }
    return [currentPage - 1, currentPage, currentPage + 1];
  };

  const displayedPages = getDisplayedPages();

  // useEffect(() => {
  //   if (partfinderUserApproved === 'yes') {
  //     handleSearch();
  //   }
  // }, [partfinderUserApproved]);

  // const handleRedirectToPartFinder = () => {
  //   window.open(
  //     'https://www.thepartfinder.com',
  //     '_blank',
  //     'noopener,noreferrer'
  //   );
  // };

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {focusedField && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10 // Ensure overlay is just below the input fields
          }}
          onClick={() => setFocusedField(null)} // Click outside any field to unfocus
          onKeyDown={e => {
            if (e.key === 'Escape') {
              setFocusedField(null); // Handle escape key to close overlay
            }
          }}
          role="button" // Assign role to indicate the div is interactive
          tabIndex={0} // Make it focusable
          aria-label="Close overlay" // Provide an accessible name
        />
      )}

      <div
        style={{
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          margin: '20px',
          padding: '20px',
          borderRadius: '8px',
          backgroundColor: 'lightBlue'
        }}
      >
        <h2
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
            textAlign: 'center'
          }}
        >
          Send General Requests
        </h2>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            justifyContent: 'center',
            marginBottom: '10px',
            marginTop: '10px',
            width: '100%'
          }}
        >
          <input
            id="partNumber"
            type="text"
            value={partNumber}
            onChange={handleInputChange}
            placeholder="Enter Part Number"
            onFocus={() => setFocusedField('partNumber')}
            onBlur={() => setFocusedField(null)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSearch();
                e.target.blur();
              }
            }}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '2px solid #ccc',
              order: 1,
              minWidth: '150px',
              flex: `0 1 ${getGeneralFlexBasis(windowWidth, 0)}`,
              height: '50px',
              zIndex: focusedField ? 11 : 1
            }}
          />

          <input
            id="modelnum"
            type="text"
            value={modelnum}
            onChange={handleModelNumberChange}
            placeholder="Enter Modelnum"
            onFocus={() => setFocusedField('modelnum')}
            onBlur={() => setFocusedField(null)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSearch();
                e.target.blur();
              }
            }}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '2px solid #ccc',
              order: 2,
              minWidth: '150px',
              flex: `0 1 ${getGeneralFlexBasis(windowWidth, 1)}`,
              height: '50px',
              zIndex: focusedField ? 11 : 1
            }}
          />

          <select
            value={manufacturer}
            onChange={handleManufacturerChange}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '2px solid #ccc',
              order: 3,
              minWidth: '150px',
              flex: `0 1 ${getGeneralFlexBasis(windowWidth, 2)}`,
              height: '50px',
              zIndex: focusedField ? 11 : 1
            }}
          >
            <option value="All">All Manufacturers</option>
            {manufList.map(manuf => (
              <option key={manuf.id} value={manuf.abbrname}>
                {manuf.abbrname}
              </option>
            ))}
          </select>

          <button
            type="button"
            onClick={handleSearch}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '4px',
              backgroundColor: '#007BFF',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              height: '50px',
              maxWidth: '150px',
              flex: `1 1 ${getGeneralFlexBasis(windowWidth, 3)}`,
              zIndex: focusedField ? 11 : 1,
              order: windowWidth > 1025 ? 5 : 8 // Adjust order based on windowWidth
            }}
          >
            Send
          </button>
        </div>
        <div
          style={{
            flex: '1 1 100%',
            order: 7,
            marginLeft: '15px',
            marginRight: '15px'
          }}
        >
          <textarea
            id="comments"
            value={comments}
            onChange={handleCommentChange}
            placeholder="Enter Comments (Required)"
            onFocus={() => setFocusedField('comments')}
            onBlur={() => setFocusedField(null)}
            style={{
              padding: '10px',
              fontSize: '16px',
              position: 'relative',
              borderRadius: '4px',
              border: '2px solid #ccc',
              height: '100px',
              width: '100%',
              zIndex: focusedField ? 11 : 1
            }}
          />
          {errorMessage && (
            <div
              style={{ marginTop: '10px', color: 'red', textAlign: 'center' }}
            >
              {errorMessage}
            </div>
          )}
          {error && (
            <div
              style={{ marginTop: '10px', color: 'red', textAlign: 'center' }}
            >
              {error}
            </div>
          )}
          {generalRequest &&
            generalRequest.generalRequestId &&
            !errorMessage && (
              <div
                style={{
                  marginTop: '10px',
                  color: 'green',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                Request message successfully sent out!
              </div>
            )}
        </div>
      </div>
      {allGeneralRequests &&
        allGeneralRequests.rows &&
        allGeneralRequests.rows.length > 0 && (
          <div
            style={{
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              margin: '20px',
              padding: '20px',
              borderRadius: '8px',
              backgroundColor: 'lightBlue'
            }}
          >
            <h2
              style={{
                paddingTop: '20px',
                paddingBottom: '20px',
                textAlign: 'center'
              }}
            >
              Your Previous General Requests
            </h2>
            <Table
              className="table-striped"
              style={{ margin: '0', padding: '0' }}
            >
              <thead>
                <tr>
                  <th>Part Number</th>
                  <th>Manufacturer</th>
                  <th>Model Number</th>
                  <th>Comments</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {allGeneralRequests.rows.map(req => (
                  <tr key={req.id || req.generalRequestId}>
                    <td>{req.partNumber || 'N/A'}</td>
                    <td>
                      {req.manufacturer &&
                      req.manufacturer.toLowerCase() === 'all'
                        ? 'N/A'
                        : req.manufacturer || 'N/A'}
                    </td>{' '}
                    <td>{req.modelnum || 'N/A'}</td>
                    <td style={{ whiteSpace: 'pre-line' }}>{req.comments}</td>
                    <td>
                      {req.createdAt
                        ? new Date(req.createdAt).toLocaleString()
                        : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {totalRequests > 10 && (
              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                {/* Previous Arrow */}
                {currentPage > 1 && (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage - 1)}
                    style={{
                      margin: '0 5px',
                      padding: '5px 10px',
                      backgroundColor: '#f2f2f2',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}
                  >
                    ←
                  </button>
                )}

                {/* Page Numbers */}
                {displayedPages.map(number => (
                  <button
                    key={number}
                    onClick={() => handlePageChange(number)}
                    type="button"
                    style={{
                      margin: '0 5px',
                      padding: '5px 10px',
                      backgroundColor:
                        currentPage === number ? '#007BFF' : '#f2f2f2',
                      color: currentPage === number ? '#fff' : '#000',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}
                  >
                    {number}
                  </button>
                ))}

                {/* Next Arrow */}
                {currentPage < totalPages && (
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    type="button"
                    style={{
                      margin: '0 5px',
                      padding: '5px 10px',
                      backgroundColor: '#f2f2f2',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}
                  >
                    →
                  </button>
                )}
              </div>
            )}
          </div>
        )}
    </div>
  );
}

export default GeneralRequest;
