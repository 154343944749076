import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { isTokenExpired, decodeToken } from '../utils/jwtUtils';
import refreshAccessToken from '../utils/refreshToken';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken')
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem('refreshToken')
  );
  const [logoutMessage, setLogoutMessage] = useState('');
  // Decode the access token to get user info
  const [userInfo, setUserInfo] = useState(() => {
    const token = localStorage.getItem('accessToken');
    return token ? decodeToken(token) : null;
  });

  const updateTokens = (newAccessToken, newRefreshToken) => {
    localStorage.setItem('accessToken', newAccessToken);
    localStorage.setItem('refreshToken', newRefreshToken);
    setAccessToken(newAccessToken);
    setRefreshToken(newRefreshToken);
    setUserInfo(decodeToken(newAccessToken)); // Decode the new access token and update user info
  };

  // Add logout method
  const logout = async (message = 'You have been logged out.') => {
    // Before removing tokens from local storage, make an API call to delete the refresh token
    if (refreshToken) {
      try {
        const response = await fetch(`${baseUrl}/api/logout`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ refreshToken })
        });

        if (!response.ok) {
          throw new Error('Failed to log out on server.');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Logout error:', error);
      }
    }

    // Clear tokens from local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setAccessToken(null);
    setRefreshToken(null);
    setLogoutMessage(message);
  };

  const attemptTokenRefresh = async () => {
    if (refreshToken && isTokenExpired(accessToken)) {
      const tokens = await refreshAccessToken(
        baseUrl,
        refreshToken,
        updateTokens,
        logout // Pass logout function to handle failures gracefully
      );
      if (tokens) {
        updateTokens(tokens.accessToken, tokens.refreshToken);
      } else {
        // console.info('Failed to refresh tokens');
        // No need to call logout again here, as it's handled in refreshAccessToken
      }
    }
  };

  useEffect(() => {
    attemptTokenRefresh();
  }, []);

  useEffect(() => {
    // This will check for updates to local storage - will update all tabs to the newest local storage.
    const handleStorageChange = event => {
      if (event.key === 'accessToken') {
        if (event.newValue) {
          setAccessToken(event.newValue);
          setUserInfo(decodeToken(event.newValue));
        } else {
          setAccessToken(null);
          setUserInfo(null);
        }
      }

      if (event.key === 'refreshToken') {
        if (event.newValue) {
          setRefreshToken(event.newValue);
        } else {
          setRefreshToken(null);
        }
      }

      if (event.key === 'logout') {
        const message = event.newValue || 'You have been logged out.';
        logout(message);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [logout]);

  // Use useMemo to memoize the value object
  const contextValue = useMemo(
    () => ({
      accessToken,
      refreshToken,
      updateTokens,
      attemptTokenRefresh,
      logoutMessage,
      setLogoutMessage,
      logout,
      userInfo
    }),
    [accessToken, refreshToken, logoutMessage, userInfo]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
