import React, { useEffect, useState } from 'react';
import {
  Row,
  Card,
  Badge,
  Button,
  Container,
  Form,
  Modal,
  Alert,
  Col
} from 'react-bootstrap';
import usePartSearch from '../hooks/usePartSearch';

function PartSearchesList() {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { thisWeekSearches, totalPages, getPartSearchesThisWeek } =
    usePartSearch(baseUrl);

  // Variables for messaging
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [currentPartMessage, setCurrentPartMessage] = useState('');

  // State for sending messages
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [sending, setSending] = useState(false);
  const [sendError, setSendError] = useState('');
  const [partMessages, setPartMessages] = useState({});
  const [globalErrorMessage, setGlobalErrorMessage] = useState('');
  const [page, setPage] = useState(1);
  const limit = 25; // Fixed limit, or make it state if you want to change it

  useEffect(() => {
    const fetchPartSearches = async () => {
      await getPartSearchesThisWeek(page, limit);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100); // 100ms delay; adjust as needed
    };

    // Fetch immediately on mount
    fetchPartSearches();

    // Reloads messages every 5 minute - 60000 for 1 min
    const intervalId = setInterval(fetchPartSearches, 300000);

    return () => clearInterval(intervalId);
  }, [page, limit]);

  const openMessageModal = search => {
    setCurrentPartMessage(search);
    setSubject(`Messaging about your search for #${search.partNumber || ''}`);
    setBody(''); // Clear the message body
    setSendError(''); // Clear any previous errors
    setGlobalErrorMessage('');
    setShowMessageModal(true);
  };

  const closeMessageModal = () => {
    setShowMessageModal(false);
    setGlobalErrorMessage('');
    setSendError('');
  };

  const handleSendMessage = async () => {
    if (!subject || !body) {
      setSendError('Subject and body are required.');
      return;
    }

    setSending(true);
    setSendError('');
    setGlobalErrorMessage(''); // Clear any previous global errors

    const messageData = {
      recipientCompanyId: currentPartMessage.User.companyId,
      subject,
      body,
      relatedPartsearchId: currentPartMessage.id,
      messageType: 'partsearches'
    };

    try {
      const response = await fetch(`${baseUrl}/api/message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify(messageData)
      });

      const result = await response.json();

      if (response.ok) {
        // Update partMessages with a success message for this part
        setPartMessages(prevMessages => ({
          ...prevMessages,
          [currentPartMessage.id]: `Message successfully sent to ${currentPartMessage.User.Company.name}.`
        }));
        closeMessageModal();
      } else {
        // Close the modal and set the global error message
        closeMessageModal();
        setGlobalErrorMessage(result.message || 'Failed to send message.');
      }
    } catch (err) {
      // Close the modal and set the global error message
      closeMessageModal();
      setGlobalErrorMessage('An error occurred while sending the message.');
    } finally {
      setSending(false);
    }
  };

  return (
    <Container className="mt-4">
      <h1 className="mb-4">Part Searches This Week</h1>
      {thisWeekSearches.length === 0 ? (
        <p>No part searches found this week.</p>
      ) : (
        <div>
          {thisWeekSearches.map(search => (
            <Row key={search.id} className="mb-3">
              <Card className="h-100">
                <Card.Header style={{ padding: '15px' }}>
                  <div className="row">
                    <div className="col-6 col-lg-3 d-flex align-items-center">
                      <Badge bg="primary" className="me-2">
                        From:
                      </Badge>
                      <span>
                        <strong>
                          {search.User.firstName} {search.User.lastName}
                        </strong>
                      </span>
                    </div>
                    <div className="col-6 col-lg-3 d-flex align-items-center">
                      <Badge bg="warning" className="me-2">
                        Company:
                      </Badge>
                      <span>{search.User.Company.name}</span>
                    </div>
                    <div className="col-6 col-lg-3 d-flex align-items-center">
                      <Badge bg="secondary" className="me-2">
                        Email:
                      </Badge>
                      <span>{search.User.email}</span>
                    </div>
                    <div className="col-6 col-lg-3 d-flex align-items-center">
                      <Badge bg="info" className="me-2">
                        Created At:
                      </Badge>
                      <span>{new Date(search.createdAt).toLocaleString()}</span>
                    </div>
                  </div>
                </Card.Header>

                <Card.Body>
                  <dl className="row">
                    {search.partNumber && (
                      <>
                        <dt className="col-sm-4">Partnumber:</dt>
                        <dd className="col-sm-8">
                          <span
                            style={{
                              backgroundColor:
                                search.matchCount > 0 ? 'yellow' : 'transparent'
                            }}
                          >
                            {search.partNumber}
                          </span>
                        </dd>
                      </>
                    )}
                    {search.condition && (
                      <>
                        <dt className="col-sm-4">Condition:</dt>
                        <dd className="col-sm-8">{search.condition}</dd>
                      </>
                    )}
                    {search.manufacturer && (
                      <>
                        <dt className="col-sm-4">Manufacturer:</dt>
                        <dd className="col-sm-8">{search.manufacturer}</dd>
                      </>
                    )}
                    {search.description && (
                      <>
                        <dt className="col-sm-4">Description:</dt>
                        <dd className="col-sm-8">{search.description}</dd>
                      </>
                    )}
                    {search.comments && (
                      <>
                        <dt className="col-sm-4">Comments:</dt>
                        <dd
                          style={{ whiteSpace: 'pre-line' }}
                          className="col-sm-8"
                        >
                          {search.comments}
                        </dd>
                      </>
                    )}
                  </dl>
                </Card.Body>

                <Card.Footer className="text-end">
                  <div className="d-flex justify-content-between align-items-center">
                    {search.hasBeenMessaged ? (
                      <Badge bg="warning" text="dark">
                        Your company has already replied to this part search
                      </Badge>
                    ) : (
                      <div style={{ width: '1em' }} />
                    )}
                    <Button
                      variant="primary"
                      size="md"
                      onClick={() => openMessageModal(search)}
                    >
                      Message
                    </Button>
                  </div>
                  {partMessages[search.id] && (
                    <p style={{ color: 'green', marginTop: '3px' }}>
                      {partMessages[search.id]}
                    </p>
                  )}
                </Card.Footer>
              </Card>
            </Row>
          ))}

          {/* Message Modal */}
          <Modal
            show={showMessageModal}
            onHide={closeMessageModal}
            size="md"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {currentPartMessage
                  ? `Send Message to ${currentPartMessage.User.Company.name}`
                  : 'Send Message'}{' '}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {sendError && <Alert variant="danger">{sendError}</Alert>}
              {globalErrorMessage && (
                <Alert variant="danger">{globalErrorMessage}</Alert>
              )}
              <Form>
                <Form.Group controlId="formSubject">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="text"
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                    placeholder="Enter subject"
                  />
                </Form.Group>
                <Form.Group controlId="formBody">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={body}
                    onChange={e => setBody(e.target.value)}
                    placeholder="Enter your message"
                  />
                </Form.Group>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={closeMessageModal}
                disabled={sending}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSendMessage}
                disabled={sending}
              >
                {sending ? 'Sending...' : 'Send Message'}
              </Button>
            </Modal.Footer>
          </Modal>

          <Row className="mt-3">
            <Col
              xs={4}
              className="d-flex justify-content-start"
              style={{ paddingBottom: '10px' }}
            >
              <Button
                onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                disabled={page === 1}
                style={{ width: '105px', height: '40px' }} // Symmetrical padding
              >
                &lt; Previous
              </Button>
            </Col>

            <Col xs={4} className="d-flex justify-content-center">
              <span>
                Page {page} of {totalPages}
              </span>
            </Col>

            <Col
              xs={4}
              className="d-flex justify-content-end"
              style={{ paddingBottom: '10px' }}
            >
              <Button
                onClick={() => setPage(prev => prev + 1)}
                disabled={page === totalPages}
                style={{ width: '105px', height: '40px' }} // Symmetrical padding
              >
                Next &gt;
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
}

export default PartSearchesList;
